import {FormControl, FormHelperText, FormLabel, RadioGroup} from '@mui/material';
import * as React from 'react';
import {forwardRef, memo} from 'react';

const RadioGroupField = forwardRef(({id, name, label, helperText, children, RadioGroupProps, ...props}, ref) => {
    const label_id = `${id}-label`;

    return (
        <FormControl component="fieldset" {...props}>
            <FormLabel id={label_id}>{label}</FormLabel>

            <RadioGroup aria-labelledby={label_id} name={name} {...RadioGroupProps}>
                {children}
            </RadioGroup>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
});

export default memo(RadioGroupField);
